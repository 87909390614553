<template>
<v-container>

<v-row>
    <v-card elevation="2" outlined shaped class = "mx-2">
    <v-col>

  <v-data-table
     dense
    :headers = "headers"
    :items = "whos"
    :items-per-page = "11"
     class="elevation-1"
  > </v-data-table>

  </v-col>  
  </v-card>

</v-row>  

</v-container>
</template>

<!------------------------------------------------------------>
<script>

  const STORAGE_KEY = 'astroSimpatico';

  import { getters }        from '../mixins/getters'        ;
  import { getter_Chinese } from '../mixins/getter_Chinese' ;
  import { calc_Hearts }    from '../mixins/calc_Hearts' ;
  import { calc_Stars }     from '../mixins/calc_Stars' ;

  export default {

    data: () => ({
                    whos: [],

                    headers: [
                              { text: 'who',        value: 'id',     align: 'start',  sortable: false   },
                              { text: 'birth_date', value: 'bday'    },
                              { text: 'sign',       value: 'sign'    },
                              { text: 'year_Of',    value: 'year_Of' },
                            ],
                  }),
  //_________________________________________________________________________________________________________________________________
  created() {
     let whos = [] ;
     this.whos = JSON.parse(sessionStorage.getItem(STORAGE_KEY) || '[]' ) ;
  },

  //_________________________________________________________________________________________________________________________________

  mixins: [getters, calc_Hearts, calc_Stars, getter_Chinese],

  //_________________________________________________________________________________________________________________________________
  methods: {

    remove_who(who){
                      this.whos.splice(this.whos.indexOf(who), 1 );
                      sessionStorage.setItem(STORAGE_KEY, JSON.stringify(this.whos) ) ;
                   }, 
    //_________________________________________________________________________
    pick_Top_User(who) {
                          let xx_Index   = this.whos.indexOf(who) ; 
                          let top_Person = this.whos[xx_Index] ;

                                          this.whos.splice( xx_Index, 1 );
                                          this.whos.unshift( top_Person ) ;
                        },
  }              
  }
</script>
